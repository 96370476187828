<template>
	<div>
		<Header title="用户界面"></Header>
		<Main>
			<router-view></router-view>
		</Main>
	</div>
</template>

<script>
	import Header from "@/components/common/Header";
	import Main from "@/components/common/Main";
	export default {
		name: "User",
		components: {
			Header,
			Main
		}
	}
</script>

<style scoped>

</style>
